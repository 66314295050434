import React, { useState, useEffect } from 'react';
import { getReceipt } from '../../api/payments';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { ReactComponent as AirParkLogo } from '../../assets/icons/ico_airpark_logo_no_bg.svg';
import { ReactComponent as TicketIcon } from '../../assets/icons/ico_ticket_green.svg';
import { ReactComponent as SpotBubble } from '../../assets/icons/ico_spot_bubble.svg';
import { ReactComponent as SpotIcon } from '../../assets/icons/ico_p_spot.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/ico_calendar.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/ico_clock.svg';
import { ReactComponent as ElectricityIcon } from '../../assets/icons/ico_electricity_black.svg';
import { ReactComponent as IndoorIcon } from '../../assets/icons/ico_garage_black.svg';
import { ReactComponent as FencedIcon } from '../../assets/icons/ico_fence_black.svg';
import { ReactComponent as CarIcon } from '../../assets/icons/ico_car.svg';
import { ReactComponent as CreditCard } from '../../assets/icons/ico_credit_card.svg';
import { useTranslation } from 'react-i18next';

const PaymentPage = ({}) => {
    const [receipt, setReceipt] = useState(null);
    const { paymentExtId } = useParams();
    const { t } = useTranslation();
    const [ loading, setLoading ] = useState(true);

    console.log(receipt)

    useEffect(() => {
      const handleGetReceipt = async () => {
        try{
          const response = await getReceipt(paymentExtId);
          setLoading(false);
          if(response?.error) {
            //TODO: Handle error
          } else if (response) {
            setReceipt(response);
          }
        } catch (error) {
          setLoading(false);
          //TODO: Handle error
        }
      }
      handleGetReceipt();
    }, []);

    return (
      <>
      <div className="flex justify-center min-h-screen w-full bg-white overflow-hidden relative pb-3">

        <div className="flex max-w-3xl w-full flex-col p-4">

            <div className='flex w-full justify-between'>
              <a title="AirPark Pay" href="/">
              <AirParkLogo className="h-12" />
              </a>

              {receipt ?
                <div className="h-12">
                  <p className='text-sm'>{t('receiptCreated')}</p>
                  <p className='text-sm'>{moment(receipt.receiptDate).format('YYMMDD, HH:mm')}</p>
                </div>
              :null}
             
            </div>

            <div className='flex flex-col items-center w-full mt-12'>
              <TicketIcon className="h-12" />
              <h1 className='text-3xl'>{t('receiptForPayment')}</h1>
            </div>
            
            {receipt ?
            <div className='flex flex-col StyledReceipt w-full mt-14'>
              <div className='flex items-center p-4'>
                <SpotBubble className="h-16 w-16" />
                <h2 className="w-auto text-xl font-medium text-airpark-green">{receipt.parkingAreaName}</h2>
              </div>

              <div className='flex items-center mt-2 pl-8 pr-8'>
                    <CalendarIcon className="h-6 w-6" />
                    <p className="w-auto ml-2">{receipt.friendlyPeriod}</p>
                  </div>

              <div className='flex pl-8'>

                <div className='flex flex-col w-full'>


                {receipt.spotIdentifier ?
                  <div className='flex items-center mt-2'>
                    <SpotIcon className="h-6 w-6" />
                    <p className="w-auto ml-2">{receipt.spotIdentifier}</p>
                  </div>
                :null}

                  <div className='flex items-center mt-2'>
                    {receipt.parkingSpotFeatures?.hasChargingPost ?
                      <ElectricityIcon className="h-6 w-6 mr-2" />
                    :null}

                    {receipt.parkingSpotFeatures?.isIndoor ?
                      <IndoorIcon className="h-6 w-6 mr-2" />
                    :null}

                    {receipt.parkingSpotFeatures?.isFenced ?
                      <FencedIcon className="h-6 w-6 mr-2" />
                    :null}

                  </div>

                </div>

                <div className='flex flex-col w-full pr-8'>

                  <div className='flex items-center justify-end mt-2'>
                    <p className="w-auto">{receipt.registrationIdentifier}</p>
                    <CarIcon className="h-6 w-6 ml-2" />
                  </div>

                  <div className='flex items-center justify-end mt-2'>
                    <p className="w-auto">{paymentExtId}</p>
                    <CreditCard className="h-6 w-6 ml-2" />
                  </div>

                </div>
              </div>

              <div className='flex pl-8 pr-8 mt-8'>
                  <div className="flex w-full">
                      <p className="">{t('transactionFee')}</p>
                  </div>
                  <div className="flex justify-end w-full">
                    <p className="">{`${receipt.transactionFee} ${receipt.currencySymbol}`}</p>
                  </div>
              </div>

              <div className='flex mt-1 pl-8 pr-8'>
                  <div className="flex w-full">
                      <p className="">{`${t('transactionFeeVat')} (${receipt.transactionFeeVATPercentage}%)`}</p>
                  </div>
                  <div className="flex justify-end w-full">
                    <p className="">{`${receipt.transactionFeeVATAmount} ${receipt.currencySymbol}`}</p>
                  </div>
              </div>

              <div className='flex mt-1 pl-8 pr-8'>
                  <div className="flex w-full">
                      <p className="">{t('airParkFee')}</p>
                  </div>
                  <div className="flex justify-end w-full">
                    <p className="">{`${receipt.airParkFee} ${receipt.currencySymbol}`}</p>
                  </div>
              </div>

              <div className='flex mt-1 pl-8 pr-8'>
                  <div className="flex w-full">
                      <p className="">{`${t('airParkFeeVat')} (${receipt.airParkVATPercentage}%)`}</p>
                  </div>
                  <div className="flex justify-end w-full">
                    <p className="">{`${receipt.airParkVATAmount} ${receipt.currencySymbol}`}</p>
                  </div>
              </div>

              <div className='flex mt-1 pl-8 pr-8'>
                  <div className="flex w-full">
                      <p className="">{t('parkingOwnerFee')}</p>
                  </div>
                  <div className="flex justify-end w-full">
                    <p className="">{`${receipt.parkingOwnerFee} ${receipt.currencySymbol}`}</p>
                  </div>
              </div>

              <div className='flex mt-1 pl-8 pr-8'>
                  <div className="flex w-full">
                      <p className="">{`${t('parkingOwnerVat')} (${receipt.parkingOwnerVATAmount}%)`}</p>
                  </div>
                  <div className="flex justify-end w-full">
                    <p className="">{`${receipt.parkingOwnerVATAmount} ${receipt.currencySymbol}`}</p>
                  </div>
              </div>


              <div className='flex mt-1 pl-8 pr-8'>
                  <div className="flex w-full">
                      <p className="">{t('parkingFee')}</p>
                  </div>
                  <div className="flex justify-end w-full">
                    <p className="">{`${receipt.parkingFee} ${receipt.currencySymbol}`}</p>
                  </div>
              </div>

              <div className='flex mt-1 pl-8 pr-8'>
                  <div className="flex w-full">
                      <p className="">{`${t('parkingFeeVat')} (${receipt.parkingFeeVatPercentage}%)`}</p>
                  </div>
                  <div className="flex justify-end w-full">
                    <p className="">{`${receipt.parkingFeeVATAmount} ${receipt.currencySymbol}`}</p>
                  </div>
              </div>

              <div className='flex flex-col w-full justify-center items-center mt-4'>
                <p className='text-xl'>{t('total')}</p>
                  <p className='text-3xl font-medium'>{`${receipt.friendlyPrice}`}</p>
              </div>

              <div className='mb-16'/>
            </div>
            :null}
          <div className='flex flex-wrap sm:flex-nowrap mt-8'>
            <div className='flex flex-col w-full mt-4'>
              <p className='text-sm'>AirPark AB</p>
              <p className='text-sm'>Redaregatan 48</p>
              <p className='text-sm'>252 36 Helsingborg</p>
            </div>

            <div className='flex flex-col w-full mt-4'>
              <p className='flex justify-start sm:justify-end text-sm'>Orgnr: 559350-3138</p>
              <p className='flex justify-start sm:justify-end text-sm'>Momsregistreringsnummer:</p>
              <p className='flex justify-start sm:justify-end text-sm'>SE559350313801</p>
            </div>
          </div>

          <a href="https://www.airpark.app" className='flex justify-self-center self-center mt-4 font-medium text-airpark-green'>www.airpark.app</a>

        </div>

        
  </div>

  </>
    )
  };

export default PaymentPage;