const PAYMENTS_API = 'https://staging-payments.airpark.app/'

export const getReceipt = async (paymentExtId) => {
  const response = await fetch(`${PAYMENTS_API}Receipts/${paymentExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  })

  if(response.status === 200) {
    return await response.json().catch(() => {return response.status});
  }

  throw response
}